import { template as template_92ce2858a1fb4a498b28aafe7d97ba05 } from "@ember/template-compiler";
const SidebarSectionMessage = template_92ce2858a1fb4a498b28aafe7d97ba05(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
