import { template as template_09635cae4e0349318555e4da845c12a5 } from "@ember/template-compiler";
const FKControlMenuContainer = template_09635cae4e0349318555e4da845c12a5(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
